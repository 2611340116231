import api from '@/apis/api'
/**
 * 供应商用户相关api
 */
export class SupplierApi extends api {

  
  /**
   * 获取分页数据
   * @param {*} 分页请求数据
   */
   getRolePage({
    pageInfo,
    condition
  }) {
    return this.request({
      url: `${this.servicePath}/${this.modulePath}/rolePage`,
      method: 'post',
      pageInfo,
      data: condition
    })
  }
}

export default new SupplierApi('supplierInfo')
